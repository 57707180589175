import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage';
import OurServices from './pages/Services/Services';
import WhoWeAre from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
    return (
        <Router>
            <div className="App">
                <div className="AppContent">
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/Services" element={<OurServices />} />
                        <Route path="/AboutUs" element={<WhoWeAre />} />
                        <Route path="/ContactUs" element={<ContactUs />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
