import React, {useEffect, useRef, useState} from 'react';
import Header from "../../Header/Navbar";
import Footer from "../../Footer/Footer";
import emailjs from '@emailjs/browser';
import '../../App.css';
import './ContactUs.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

function ContactUs() {
    //used for time
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const Confirmation = async () => { // this is a little pop up
        await Swal.fire({
            icon: "success",
            title: "Email Sent",
            showConfirmButton: false,
            timer: 2000,
        });
        window.location.href = "/";
    };

    const Rejected = (_error) => { // this is a little pop up
        Swal.fire({
            icon: "error",
            title: "Email Failed To Send Please Try Emailing Me Directly",
            showConfirmButton: false,
            timer: 5000,
        });
    };

    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_h8spof7', 'template_eapc2bu', form.current, {
                publicKey: 'mU-Li717vT8kvjgxd',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    Confirmation();

                },
                (error) => {
                    console.log('FAILED...', error.text);
                    Rejected(error.text)
                },
            );

    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const [phone, setPhone] = useState("");

    const handleChange = (e) => {
        // Remove any non-numeric characters
        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
        setPhone(onlyNums);  // Update state with only numbers
    };

    return (
        <div className={"App"}>

            <Header/>

            <button
                className={"back-to-top "}
                onClick={scrollToTop}
            ><i className={"arrow up"}></i></button>

            <div className="contact-container">

                <form className="contact-form" onSubmit={sendEmail} ref={form}>
                    <label>
                        Name
                        <input
                            type="text"
                            name="user_name"
                            required
                        />
                    </label>
                    <label>
                        Email
                        <input
                            type="email"
                            name="user_email"
                            required
                        />
                    </label>
                    <label>
                        Phone Number
                        <input
                            type="tel"
                            value={phone}
                            name="user_phone"
                            onChange={handleChange}
                            maxLength="11"  // Set max length as required
                            required
                        />
                    </label>
                    <label>
                        Your Enquiry
                        <textarea
                            name="user_message"
                            required
                        />
                    </label>
                    <button type="submit">Submit</button>
                </form>

                <div className="contact-info">
                    <h3>Get in Touch</h3>

                    <p><strong>Email:</strong><a className={"links-contact-us"}
                                                 href={"mailto:juliewheeldon@bespokememories.co.uk"}> juliewheeldon@bespokememories.co.uk</a>
                    </p>
                    <p><strong>Phone:</strong><Link className={"links-contact-us"}
                                                    to="tel:7572711810"> (+44) 7572 711810</Link>
                    </p>


                    <h3>Business Hours</h3>
                    <p>Monday - Saturday: 9am to 6pm</p>
                    <p>Sunday: Closed</p>

                    <h3>Get In Touch</h3>
                    <p>We will get in touch with as quick as possible, we only answer emails during business hours and
                        we aim to answer your enquiries within 48 hours.</p>
                </div>

            </div>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
}

export default ContactUs;
