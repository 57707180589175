import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

import './Footer.css'; // Ensure you have this CSS file

function Footer() {
    const location = useLocation();

    // Scroll to top whenever location changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="footer">
            <div className="footer-container">
                {/* Contact Us Section */}
                <div className="footer-section contact-us">
                    <h3>CONTACT US</h3>
                    <p style={{fontSize: "17px"}}>Kingsbury - North Warwickshire</p>
                    <Link className={"footer-links-normal"} to="tel:7572711810"> <p className={"footer-links-text"}>(+44) 7572 711810</p></Link>
                    <a className={"footer-links-normal"} href={"mailto:juliewheeldon@bespokememories.co.uk"}><p className={"footer-links-text"}> juliewheeldon@bespokememories.co.uk</p></a>
            </div>

            {/* Extras Section */}
            <div className="footer-section extras">
                <h3>EXTRAS</h3>
                <ul>
                    <Link className={"footer-links-normal"} to="/"><li className={"footer-links-text"}>Home</li></Link>
                    <Link className={"footer-links-normal"} to="/AboutUs"><li className={"footer-links-text"}>About Us</li></Link>
                    <Link className={"footer-links-normal"} to="/Services"><li className={"footer-links-text"}>Our Services</li></Link>
                    <Link className={"footer-links-normal"} to="/ContactUs"><li className={"footer-links-text"}>Contact Us</li></Link>
                </ul>
            </div>
        </div>
            {/* Copyright Section */}
            <div className="footer-bottom">
                <p>2024 © All Rights Reserved By Bespoke Memories</p>
            </div>
        </div>
    );
}

export default Footer;
