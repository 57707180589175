import React from 'react';
import Header from "../../Header/Navbar";
import Footer from "../../Footer/Footer";
import './Services.css';
import {Link} from "react-router-dom";

function Services() {
    return (
        <div className="App">
            <div className="AppContent">
                <Header/>
                <h1 className="title-service">Our Services</h1>
                <div>
                    <div className={"row align-content-center "}>
                        <p className="description-service  top-content">
                            Our prices are flexible and can be adjusted to meet your family's specific wishes and needs.
                            I encourage you to reach out so we can discuss the type of tribute that would best honor
                            your loved one. Together, we can arrange a service that reflects their memory in a way that
                            feels meaningful and fitting for your family.
                        </p>
                    </div>

                </div>
                <div className="funeral-options-service">
                    <div className="funeral-option-service">
                        <h2>Funeral service - £220</h2>
                        <p>This price is inclusive of a cremation in a crematorium, or a graveside burial service.
                            This price also includes initial telephone call, home visit to discuss arrangements,
                            communication between yourself and me to ensure everything is perfect on the day. This can be either by email or telephone conversation, whichever you prefer.
                            This will include a final copy of the eulogy I have written for your loved one, that I will deliver on the day.</p>
                        <Link className="links-service" to="/ContactUs">Contact Us</Link>
                    </div>
                    <div className="funeral-option-service">
                        <h2>Scattering or Interring of Ashes - £75</h2>
                        <p>A meaningful way to say a final farewell is by holding a small service or reciting poems at a chosen location that holds significance. This gathering can be a quiet, intimate moment for loved ones to reflect, share memories, and find comfort in one another's presence. A poem, thoughtfully selected or written, can express emotions that are difficult to put into words, offering a sense of closure and peace.</p>
                        <Link className="links-service" to="/ContactUs">Contact Us</Link>
                    </div>
                    <div className="funeral-option-service">
                        <h2>Memorial Service - £120</h2>
                        <p>The farewell gathering can take the form of a small, intimate ceremony or a larger celebration of life, depending on the wishes of the departed and their loved ones. A quiet ceremony allows for reflection and close connection, while a celebration of life creates an opportunity to honor their legacy with joyful memories and shared stories. Whether large or small, each format provides a meaningful space for family and friends to come together, celebrate the unique spirit of the individual, and find comfort in each other's support as they say goodbye.</p>
                        <Link className="links-service" to="/ContactUs">Contact Us</Link>
                    </div>
                </div>

            </div>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
}

export default Services;
