// NavBar.js
import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";

const NavBar = () => {
    const location = useLocation();

    // Scroll to top whenever location changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <header>

            </header>
            <div className="container-fluid">
                <div className="row navBar">
                    <div className="col-sm ">
                        <h1><Link className="textDecor navTitle" to="/">Bespoke Memories</Link></h1>
                    </div>
                    <div className="col-sm navLinks">
                        <Link className="links textDecor" to="/Services">Our Services</Link>
                        <Link className="links textDecor" to="/AboutUs">Who We Are</Link>
                        <Link className="links textDecor" to="/ContactUs">Contact Us</Link>
                    </div>
                </div>
            </div>
        </>
    );
};


export default NavBar;
