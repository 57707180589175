// AboutUs.js
import React, {useEffect, useState} from 'react';
import Header from "../../Header/Navbar";
import Footer from "../../Footer/Footer";
import './AboutUs.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profileImage from "../../Images/image3.png";

function AboutUs() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) { // Show the button after scrolling down 300px
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div>
            <button
                className={"back-to-top "}
                style={{display: isVisible ? "block" : "none"}}
                onClick={scrollToTop}
            ><i className={"arrow up"}></i></button>
            <Header/>
            <div className="AboutUs-body-container">

                <div className="row align-content-center">
                    <div className="col-12 align-content-center">
                        <img className="profileImageAboutUs" src={profileImage} alt="Food and fruits"/>
                    </div>
                </div>

                <div className="row descriptive-blocksAboutUs">
                    <div className="col-12 description-textAboutUs ">
                        <h1 className="section-titleAboutUs ">Bespoke Memories</h1>
                        <p className={"start-aboutUs"} >Hi there,</p>
                        <p className={"aboutText"}>
                            My name is Julie Wheeldon, and I am a dedicated trained and certified Independent
                            Funeral Celebrant based in Kingsbury North Warwickshire. I am very proud to have
                            been trained by The Academy of Professional Celebrants.

                        </p>
                        <p className={"aboutText"}>
                            I am married with six beautiful children and two grandchildren. My family are very
                            important to me. As the eldest of three siblings, from a very young age I have always
                            wanted to look after people.
                        </p>


                        <p className={"aboutText"}>
                            After working in the Health and Social Care Industry since leaving school, working
                            my way up to a Registered Care Manger I now feel its time for a career change.

                            I am proud to say that I trained with The Academy of Professional Celebrants, and
                            am ready to deliver a memorable, unforgettable significant ceremony for your loved
                            one.
                        </p>


                        <p className={"aboutText"}>
                            I believe a person centered bespoke funeral is the last caring act that can be carried
                            out for an individual and their family.

                            Our Memories are stories about how we have spent our lives. Each person’s story is
                            different depending on the essence of these memories, and the people whose lives
                            we have touched along the way.
                            One time to make a difference.

                        </p>

                        <p className={"aboutText"}>
                            A funeral is that one chance to get it right, to make that person’s story come to life.
                            Reliving memories provides comfort to families and keeps their loved one’s memory
                            alive.
                            Being an Independent celebrant gives me that opportunity to tell that story, in the
                            eulogy that I write for your loved one.
                            It is my honour and my privilege to be able to support you and your family, through
                            this process, keeping those memories alive, whilst reflecting on their personality, and
                            recreating their life, to be able to tell their individual personal story on the day.

                        </p>



                    </div>
                </div>

            </div>

            <footer>
                <Footer/>
            </footer>
        </div>
    );
}

export default AboutUs;
