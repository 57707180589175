import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import Header from "../../Header/Navbar";
import Footer from "../../Footer/Footer";
import '../../global.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import bckImage1 from '../../Images/mainBackground.jpg';
import bckImage3 from '../../Images/thirdbackground.jpg';

import profilePic from '../../Images/image0.png'
import profilePic1 from '../../Images/image1.png'

import approvedLabel from '../../Images/approved label.png'
import {Link} from "react-router-dom";

function Homepage() {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) { // Show the button after scrolling down 300px
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (

        <div className="App">
            <Header/>
            <button
                className={"back-to-top "}
                style={{ display: isVisible ? "block" : "none" }}
                onClick={scrollToTop}
            ><i className={"arrow up"}></i></button>

            <div className="AppContent">


                {/* Sliding background */}
                <Slider {...settings} className="slider-background ">
                    <div>
                        <div className="background-slide backgroundImages">
                            <h1 className={"slider-text"}>Creating bespoke memories to honour your loved one</h1>
                            <img
                                src={bckImage1}
                                alt="Background"
                                className="backgroundImages"/>
                        </div>
                    </div>
                    <div>
                        <div className="background-slide">
                            <h1 className={"slider-text"}>Creating bespoke memories to honour your loved one</h1>
                            <img
                                src={bckImage3}
                                alt="Background"
                                className="backgroundImages"/>
                        </div>
                    </div>
                </Slider>

                {/*<h1 className={"descriptionContainer align-content-center"}>Independent Funeral Celebrant</h1>*/}


                <div className="container ">

                    <div className="row rowContainer">
                        <div className="col-lg-3 col-md-12 col-sm-12 align-content-center">

                            <div className={"align-content-center"}>
                                <img
                                    src={profilePic1}
                                    alt="Owner"
                                    className="owner-image"
                                />
                            </div>

                        </div>
                        <div className="col-sm descriptive-blocks">
                            <div>
                                <h2 className={"align-content-center descriptive-blocks-title"}>Bespoke Memories</h2>
                            </div>
                            <div>
                                <p className="description-text ">
                                    A funeral is a personal bespoke way of saying goodbye to your loved one. Whether
                                    this is a celebration of a life well lived or an acknowledgement of their life in a
                                    quieter
                                    honouring of their loss.
                                </p>

                                <p className="description-text description-section">
                                    Talking with you and your family will enable me to put all your memories together to
                                    create a tailor-made personal farewell, that can be cherished forever.
                                    A beautiful goodbye that is a lasting memory and a fitting tribute to your loved one
                                    in
                                    whichever way you choose.

                                </p>
                                <img className={"labelImage"} src={approvedLabel}/>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm small-descriptive-blocks">
                            <div>
                                <h2 className={"descriptive-blocks-title"}>A Little About Me</h2>
                            </div>
                            <div>
                                <p className="description-text description-section">
                                    I’m Julie Wheeldon, a certified Independent Funeral Celebrant from Kingsbury, North Warwickshire. With a background in care, I’m honoured to craft personalized, memorable ceremonies celebrating each individual’s unique life story.
                                </p>

                            </div>
                            <div>
                                <a className=" textDecor">

                                    <Link className="links textDecor" to="/AboutUs"><button className={"learnMoreBtn redirectButton"}>Learn More <i
                                        className={"arrow right"}></i>
                                    </button></Link>
                                </a>
                            </div>

                        </div>
                        <div className="col-sm small-descriptive-blocks">
                            <div>
                                <h2 className={"descriptive-blocks-title"}>What we can do for you</h2>
                            </div>
                            <div>
                                <p className="description-text description-section">
                                    We do funerals that can be personalized, some of the options
                                    include Scattering/Interment of Ashes, Memorials and End of life Ceremonies that can
                                    be adjusted to meet your needs.
                                </p>
                            </div>

                            <div>
                                <a className=" textDecor" >
                                    <Link className="links textDecor" to="/Services"><button className={"learnMoreBtn redirectButton"}>Learn More <i
                                        className={"arrow right"}></i>
                                    </button></Link>
                                </a>
                            </div>
                        </div>

                    </div>


                </div>
                <div className="side-image-container parallax-image-container">
                    <div className="blurred-background"></div>
                    <div className="bg-text">
                        <h1>Why Choose Bespoke Memories</h1>
                    </div>
                </div>


                <div className="container ">

                    <div className="row">
                        <div className="col-lg-3 col-sm-12 align-content-center">

                            <div className={"align-content-center"}>
                                <img
                                    src={profilePic}
                                    alt="Owner"
                                    className="owner-image"
                                />
                            </div>

                        </div>
                        <div className="col-sm descriptive-blocks">
                            <div>
                                <h2 className={"align-content-center descriptive-blocks-title descriptive-blocks-titles"}>Your
                                    Celebrant</h2>
                            </div>
                            <div>
                                <p className="description-text description-section ">
                                    As an Independent Funeral Celebrant, I create unique, heartfelt ceremonies that
                                    honor your loved one’s life through personalized tributes rather than traditional
                                    religious customs. Each service is crafted to reflect their individual story,
                                    values, and impact, incorporating cherished memories, anecdotes, favorite songs, and
                                    poems to form a lasting, meaningful tribute.

                                </p>
                                <p className="description-text description-section ">
                                    I believe family involvement is essential to making the ceremony truly personal,
                                    whether you choose to participate actively or prefer a supportive role. I provide
                                    compassionate, professional guidance from our first meeting through the final
                                    service, ensuring the process is as stress-free as possible and that every detail
                                    reflects your wishes and honors your loved one’s memory.
                                </p>
                            </div>

                        </div>
                    </div>

                </div>

                {/*<img
                                src={profilePic}
                                alt="Owner"
                                className="owner-image"
                  />*/}


            </div>
            <footer>
                <Footer/>
            </footer>
        </div>

    );
}

export default Homepage;
